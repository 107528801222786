/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-29 10:33:16
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-15 10:49:14
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Card,
  Grid,
  List,
  PullToRefresh,
  Swiper,
  Toast,
} from 'antd-mobile'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd-mobile'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppDispatch } from '../../app/store'
import styles from './doctorDetail.module.css'
import boyHeadImg from '../../components/images/headImgBoy.png'
import girlHeadImg from '../../components/images/headImgGirl.png'
import { getDoctorDetail, getDoctorDetailFlag } from './doctorDetailSlice'
import { selectAppId, selectTenantKey } from '../../app/appSlice'
import { DateTimeFormats } from '../../models/datetime'
import { Share } from "../../components/Share/share";
import { LeftOutline } from 'antd-mobile-icons'

export const DoctorDetail = () => {
  const dispatch = useDispatch<AppDispatch>()

  const history: any = useHistory()

  const [doctorDetail, setDoctorDetail] = useState<any>()

  const [status, setStatus] = useState<any>()

  const id = history?.location?.state?.id || localStorage.getItem('doctorId')

  const appid = useSelector(selectAppId)

  const tenantKey = useSelector(selectTenantKey)

  useEffect(() => {
    if (id) {
      localStorage.setItem('doctorId', id)
    }
  }, [id])

  useEffect(() => {
    if (id) {
      const href = window.location.origin?.includes('localhost')
        ? 'https://patienth5.hydhis.cn'
        : window.location?.origin
      dispatch(getDoctorDetail({ id: id, tenantKey }))
        .then(unwrapResult)
        .then((v: any) => {
          const headPhotoList = v?.headPhoto?.split('/')
          setDoctorDetail({
            ...v,
            headPhoto:
              v.headPhoto && headPhotoList?.[0] == 'proxyToOss'
                ? href + '/' + v.headPhoto
                : v.headPhoto,
          })
        });
    }
  }, [id])

  useEffect(() => {
    const startTime = moment().startOf('day').format(DateTimeFormats)
    const endTime = moment()
      .add(6, 'days')
      .startOf('days')
      .format(DateTimeFormats)
    if (id) {
      dispatch(
        getDoctorDetailFlag({
          tenantKey: tenantKey,
          doctorId: id,
          starDate: startTime,
          endDate: endTime,
        })
      )
        .then(unwrapResult)
        .then((v: any) => {
          setStatus(v?.status)
        })
    }
  }, [id])

  return (
    <div className={styles.doctorContent}>
      <span className={styles.backIcon} onClick={() => history.replace({ pathname: '/' })}><LeftOutline fontSize={16} /></span>
      <Share title={'医生详情'} param={'&doctorId=' + id} />
      <Card
        className={styles.doctorDetail}
      >
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <img
              src={
                doctorDetail?.headPhoto
                  ? doctorDetail?.headPhoto
                  : doctorDetail?.sex == 2
                    ? girlHeadImg
                    : boyHeadImg
              }
              alt=''
              className={styles.headerContentImg}
            ></img>
            <div className={styles.headerContentProduce}>
              <div className={styles.headerContentProduceName}>
                {doctorDetail?.realname || '-'}
              </div>
              <div className={styles.headerContentProduceTwo}>
                <div style={{ marginRight: '16px' }}>
                  {doctorDetail?.outpatientDepartmentName || '-'}
                </div>
                <div> {doctorDetail?.jobTitle || '-'}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.doctorDetailContent}>
          <div className={styles.doctorDetailContentTitle}>擅长</div>
          <div className={styles.doctorDetailContentMain}>
            {doctorDetail?.adeptAt || '-'}
          </div>
          <div className={styles.doctorDetailContentTitleTwo}>简介</div>
          {doctorDetail?.intro?.split('\n')?.map((v: any) => {
            return (
              <div className={styles.doctorDetailContentMainTwo} key={v}>
                {v}
              </div>
            )
          })}
        </div>
      </Card>
      {status == 0 ? (
        <div
          className={styles.doctorDetailBottom}
          onClick={() => {
            history.push({
              pathname: '/appointmentTime',
              state: {
                doctorId: doctorDetail?.id,
              },
            })
          }}
        >
          <div className={styles.doctorDetailBottomText}> 在线预约</div>
        </div>
      ) :  (
        <div
          className={styles.doctorDetailBottom}>
          <div className={styles.doctorDetailBottomText} style={{ backgroundColor: '#f5f5f5', color: '#c1c1c1' }}>该医生暂无排班信息</div>
        </div>
      )}
    </div>
  )
}
