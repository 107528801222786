import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { AppDispatch } from '../../app/store'
import styles from './doutorList.module.css'
import { useLocation } from 'react-router-dom'
import boyHeadImg from '../../components/images/headImgBoy.png'
import girlHeadImg from '../../components/images/headImgGirl.png'
import {
    getDoctorList,
} from '../home/HomeSlice'
import { selectTenantKey } from '../../app/appSlice'
import { LeftOutline } from 'antd-mobile-icons'
import {Share} from "../../components/Share/share";

export const DoctorList = () => {
    const dispatch = useDispatch<AppDispatch>()

    const history = useHistory()

  const queryParams = new URLSearchParams(window.location.search)
  const state = queryParams.get('state')

  const tenantKey = useSelector(selectTenantKey)

    const [doctorDetail, setDoctorDetail] = useState<any>()

    useEffect(() => {

      let key = tenantKey
      if (state) {
        key = state
      }

    if (key) {
      dispatch(getDoctorList({ tenantKey: key }))
        .then(unwrapResult)
        .then((res: any) => {
          const href = window.location.origin?.includes('localhost')
            ? 'https://patienth5.hydhis.cn'
            : window.location?.origin
          setDoctorDetail(
            res?.map((v: any) => {
              const headPhotoList = v?.headPhoto?.split('/')
              return {
                ...v,
                headPhoto:
                  v.headPhoto && headPhotoList?.[0] == 'proxyToOss'
                    ? href + '/' + v.headPhoto
                    : v.headPhoto,
              }
            })
          )
        })
    }
  }, [tenantKey])

    return (
        <div className={styles.container}>
            <div className={styles.backIconBox}>
                <span className={styles.backIcon} onClick={() => history.replace({ pathname: '/' })}><LeftOutline fontSize={16} /></span>
            </div>
            <div className={styles.bottomList}>
                <Share title={'医生列表'} param={''} />
                {doctorDetail?.map((v: any) => {
                    return (
                        <div className={styles.bottomContent}
                            onClick={() =>
                                history.push({
                                    pathname: '/doctorDetail',
                                    state: {
                                        id: v?.id,
                                    },
                                })
                            }>
                            <div className={styles.bottomContentLeftImg}>
                                <img
                                    src={
                                        v?.headPhoto
                                            ? v?.headPhoto
                                            : v?.sex == 2
                                                ? girlHeadImg
                                                : boyHeadImg
                                    }
                                    alt=''
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        marginRight: '16px',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                            <div
                                className={styles.bottomContentRightMain}
                            >
                                <div className={styles.bottomContentRightMainTitLe}>
                                    <div className={styles.doctorName}>
                                        {v?.realname || '-'}
                                    </div>
                                    <div className={styles.doctorProduce}>
                                        <div style={{ marginRight: '10px' }}>
                                            {v?.outpatientDepartmentName || '-'}
                                        </div>
                                        <div>{v?.jobTitle || '-'}</div>
                                    </div>
                                </div>
                                <div className={styles.bottomContentRightMainContent}>
                                    擅长：{v?.adeptAt || '-'}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}