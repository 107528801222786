/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-29 10:33:16
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-14 14:55:39
 */
import dayjs from 'dayjs'
import {
  Button,
  CapsuleTabs,
  Card,
  Grid,
  JumboTabs,
  List,
  Popup,
  PullToRefresh,
  Space,
  Calendar,
  Toast,
} from 'antd-mobile'
import { CalendarRef } from 'antd-mobile/es/components/calendar'
import moment, { now } from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Tabs } from 'antd-mobile'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppDispatch } from '../../app/store'
import styles from './appointmentTime.module.css'
import boyHeadImg from '../../components/images/headImgBoy.png'
import girlHeadImg from '../../components/images/headImgGirl.png'
import rightIconArrow from '../../components/images/rightIconArrow.png'
import deleteCross from '../../components/images/deleteCross.png'
import {
  DateTimeFormat,
  DateTimeFormats,
  DateTimeFormatSimple,
} from '../../models/datetime'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  getAppointmentTimeDataPage,
  getAppointmentTimePage,
} from './appointmentTimeSlice'
import {selectAppId, selectTenantKey} from '../../app/appSlice'
import {
  getAppointmentTimeColor,
  getAppointmentTimeName,
  getWeekDayName,
} from '../../models/appointmentTime'
import { getDoctorDetail } from '../doctorDetail/doctorDetailSlice'
import { useLocation } from 'react-router-dom'
import {Share} from "../../components/Share/share";
import { LeftOutline } from 'antd-mobile-icons'

export const AppointmentTime = () => {
  const calendarRef: any = useRef<CalendarRef>(null)

  const dispatch = useDispatch<AppDispatch>()

  const history: any = useHistory()

  const appid = useSelector(selectAppId)

  const tenantKey = useSelector(selectTenantKey)

  const [visible, setVisible] = useState(false)

  const [doctorDetail, setDoctorDetail] = useState<any>()

  const [dataValues, setDataValues] = useState<any>()

  const [timeDataList, setTimeDataList] = useState<any>()

  const [noTimeListName, setNoTimeListName] = useState<any>()

  const [bottomChangeTime, setBottomChangeTime] = useState<any>()

  const [scheduleId, setScheduleId] = useState<any>()

  const [flag, setFlag] = useState(false)

  const [timeList, setTimeList] = useState<any>()

  const nowTime = moment().format(DateTimeFormats)

  // 最大可选时间范围
  const max = new Date(
    moment().add(30, 'days').endOf('day').format(DateTimeFormatSimple)
  )

  // 弹框默认选中今天
  const defaultToday = new Date()

  const [defaultSingle, setDefaultSingle] = useState<any>()

  // const defaultSingle: any = moment(defaultToday).format(DateTimeFormats)

  //按时间
  const doctorId =
    history.location?.state?.doctorId || localStorage.getItem('doctorId') //预约所需要的医生id

  const tab = history.location?.state?.tab || localStorage.getItem('tab')

  // 点击下一步返回后上边时间要选择之前选中的时间 ,按医生 tab ==0
  const appointmentTimeDefaultTime = localStorage.getItem(
    'appointmentTimeDefaultTime'
  )

  //按时间 tab ==1 , 外边选中的时间,进入页面后要跟外边时间保持一致,有一个默认外边时间
  const defaultTime: any = localStorage.getItem('defaultTime')

  const [changeTime, setChangeTime] = useState<any>(nowTime) //点击上边tab选中那个的时间，用于筛选下边的时间段，默认今天

  const [defaultActiveTime, setDefaultActiveTime] = useState<any>()

  useEffect(() => {
    console.log(visible, 'visible')
    const data = moment(defaultToday).format(DateTimeFormats)
    if (!visible) {
      setDefaultSingle(data)
      // calendarRef.current?.jumpToToday()
    } else {
      return
    }
  }, [visible])

  useEffect(() => {
    setFlag(false)
  }, [])

  useEffect(() => {
    if (doctorId || tab) {
      localStorage.setItem('doctorId', doctorId ? doctorId : '')
      localStorage.setItem('tab', tab ? tab : '')
    }
  }, [doctorId, tab])

  useEffect(() => {
    if (tab == '1') {
      localStorage.setItem('defaultTime', defaultTime)
    }
  }, [tab, history])

  useEffect(() => {
    localStorage.removeItem('detailData')
  }, [])

  const dayName = [
    '今天',
    '明天',
    '周三',
    '周四',
    '周五',
    '周六',
    '周日',
    '周一',
    '周二',
    '周三',
    '周四',
    '周五',
    '周六',
    '周日',
    '周一',
    '周二',
    '周三',
    '周四',
    '周五',
    '周六',
    '周日',
    '周一',
    '周二',
    '周三',
    '周四',
    '周五',
    '周六',
    '周日',
    '周一',
    '周二',
    '周三',
  ]

  useEffect(() => {
    console.log(timeDataList, tab, 'defaultTime')
    // 按医生返回
    if (appointmentTimeDefaultTime && tab == 0) {
      dispatch(
        getAppointmentTimePage({
          tenantKey: tenantKey,
          date: moment(appointmentTimeDefaultTime).format(DateTimeFormats),
          doctorId: doctorId,
        })
      )
        .then(unwrapResult)
        .then((v: any) => {
          setTimeList(v)
          resetCapsuleCss()
        })
        .catch((c) => {
          setNoTimeListName(c?.message)
          setTimeList([])
        })
    } else if (defaultTime && tab == 1) {
      dispatch(
        getAppointmentTimePage({
          tenantKey: tenantKey,
          date: moment(defaultTime).format(DateTimeFormats),
          doctorId: doctorId,
        })
      )
        .then(unwrapResult)
        .then((v: any) => {
          setTimeList(v)
          resetCapsuleCss()
        })
        .catch((c) => {
          setNoTimeListName(c?.message)
          setTimeList([])
        })
    } else {
      // 下边的时间段
      dispatch(
        getAppointmentTimePage({
          tenantKey: tenantKey,
          date: changeTime
            ? moment(changeTime).format(DateTimeFormats)
            : moment().format(DateTimeFormats),
          doctorId: doctorId,
        })
      )
        .then(unwrapResult)
        .then((v: any) => {
          setTimeList(v)
          resetCapsuleCss()
        })
        .catch((c) => {
          setNoTimeListName(c?.message)
          setTimeList([])
        })
    }
  }, [appointmentTimeDefaultTime, defaultTime, tab, changeTime])

  const resetCapsuleCss = () => {
    // let AllElement = document.getElementsByClassName(
    //     'adm-capsule-tabs-tab'
    // ) as any
    // if (AllElement) {
    //     for (var i = 0; i < AllElement.length; i++) {
    //         AllElement[i].style.margin = '5px'
    //     }
    // }
    var emptyDom1 = document.getElementById('emptyDom1') as any
    var emptyDom2 = document.getElementById('emptyDom2') as any
    emptyDom1?.remove()
    emptyDom2?.remove()
    let baseEle = document.getElementsByClassName(
      'adm-capsule-tabs-tab-wrapper'
    ) as any
    //最后一行为两个的时候，追加一条空的
    var divDom = document.createElement('div')
    divDom.className = 'adm-capsule-tabs-tab-wrapper'
    divDom.setAttribute('id', 'emptyDom1')
    if (baseEle && baseEle.length > 0 && baseEle.length % 3 == 2) {
      baseEle[baseEle.length - 1].after(divDom)
      //最后一行为两个的时候，追加两条空的
    } else if (baseEle && baseEle.length > 0 && baseEle.length % 3 == 1) {
      baseEle[baseEle.length - 1].after(divDom)
      var divDom2 = document.createElement('div')
      divDom2.className = 'adm-capsule-tabs-tab-wrapper'
      divDom2.setAttribute('id', 'emptyDom2')
      baseEle[baseEle.length - 1].after(divDom2)
    }
  }
  useEffect(() => {
    // 上边的时间tab
    const startTime = moment().startOf('day').format(DateTimeFormats)
    const endTime = moment()
      .add(30, 'days')
      .startOf('days')
      .format(DateTimeFormats)
    dispatch(
      getAppointmentTimeDataPage({
        tenantKey: tenantKey,
        starDate: startTime,
        endDate: endTime,
        doctorId: doctorId,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setTimeDataList(
          res?.map((res: any, index: any) => {
            return {
              // dayName[index]
              name:
                moment().format(DateTimeFormatSimple) ==
                moment(res.date).format(DateTimeFormatSimple)
                  ? '今天'
                  : moment().add(1, 'days').format(DateTimeFormatSimple) ==
                    moment(res.date).format(DateTimeFormatSimple)
                  ? '明天'
                  : getWeekDayName(new Date(res.date).getDay()?.toString()),
              date: res.date,
              status: res?.status,
            }
          })
        )
      })
  }, [])

  useEffect(() => {
    if (doctorId) {
      const href = window.location.origin?.includes('localhost')
        ? 'https://patienth5.hydhis.cn'
        : window.location?.origin
      dispatch(getDoctorDetail({ id: doctorId, tenantKey }))
        .then(unwrapResult)
        .then((v: any) => {
          const headPhotoList = v?.headPhoto?.split('/')
          setDoctorDetail({
            ...v,
            headPhoto:
              v.headPhoto && headPhotoList?.[0] == 'proxyToOss'
                ? href + '/' + v.headPhoto
                : v.headPhoto,
          })
        })
    }
  }, [doctorId])

  useEffect(() => {
    setBottomChangeTime('')
    // 清空数据
    localStorage.removeItem('detailData')
  }, [])

  useEffect(() => {
    // 按医生点击预约进入后点击下一步回来展示原先选中的值
    console.log(changeTime, 'changeTime')
    if (changeTime) {
      if (
        changeTime == moment().format(DateTimeFormats) &&
        appointmentTimeDefaultTime
      ) {
        setDefaultActiveTime(
          moment(appointmentTimeDefaultTime).format(DateTimeFormats)
        )
      } else {
        setDefaultActiveTime(
          moment(
            defaultTime ? defaultTime : changeTime ? changeTime : ''
          ).format(DateTimeFormats)
        )
      }
    } else {
      setDefaultActiveTime(nowTime)
    }
  }, [changeTime, defaultTime, defaultActiveTime, appointmentTimeDefaultTime])

  return (
    <div className={styles.appointmentTimeCardContent}>
      <span className={styles.backIcon} onClick={() => history.replace({ pathname: '/' })}><LeftOutline fontSize={16} /></span>
      <Share title={'预约'} param={'&doctorId='+doctorId+'&tab='+tab+'&defaultTime'+defaultTime}/>
      <div className={styles.container}>
        <div className={styles.cardOne}>
          <Card className={styles.appointmentTimeCardContentCard}>
            <img
              src={
                doctorDetail?.headPhoto
                  ? doctorDetail?.headPhoto
                  : doctorDetail?.sex == 2
                  ? girlHeadImg
                  : boyHeadImg
              }
              alt=''
              className={styles.appointmentTimeCardContentImg}
            ></img>
            <div className={styles.appointmentTimeCardContentMain}>
              <div className={styles.appointmentTimeCardContentName}>
                {doctorDetail?.realname || '-'}
              </div>
              <div className={styles.appointmentTimeCardContentType}>
                {doctorDetail?.outpatientDepartmentName || '-'}
              </div>
            </div>
            <div
              className={styles.appointmentTimeCardContentDetail}
              onClick={() => {
                history.push({
                  pathname: '/doctorDetail',
                  state: {
                    id: doctorDetail?.id,
                  },
                })
              }}
            >
              医生详情
              <img
                src={rightIconArrow}
                alt=''
                style={{
                  width: '16px',
                  height: '16px',
                  marginLeft: '4px',
                  marginTop: '2px',
                }}
              />
            </div>
          </Card>
        </div>
        <div className={styles.appointmentTimeCardContentTabsMain}>
          <JumboTabs
            className={styles.appointmentTimeCardContentTabs}
            activeKey={defaultActiveTime}
            defaultActiveKey={
              defaultTime
                ? moment(defaultTime).format(DateTimeFormats)
                : nowTime
            }
            onChange={(v) => {
              setChangeTime(v)
              localStorage.removeItem('appointmentTimeDefaultTime')
              localStorage.removeItem('defaultTime')
              // setFlag(true)
            }}
          >
            {timeDataList?.map((v: any, index: any) => {
              return (
                <JumboTabs.Tab
                  title=''
                  description={
                    <div className={styles.dataMain}>
                      <div className={styles.data}>{v?.name}</div>
                      <div className={styles.dataAppointmentTime}>
                        {moment(v?.date).format('MM/DD')}
                      </div>
                      <div
                        className={styles.dataState}
                        style={{
                          color: getAppointmentTimeColor(Number(v?.status)),
                        }}
                      >
                        {getAppointmentTimeName(Number(v?.status))}
                      </div>
                    </div>
                  }
                  key={v?.date}
                />
              )
            })}
          </JumboTabs>
          <div
            className={styles.appointmentTimeCardContentRightIcon}
            onClick={() => {
              setVisible(true)
            }}
          >
            <img
              src={rightIconArrow}
              alt=''
              style={{
                width: '16px',
                height: '16px',
                marginLeft: '4px',
                marginTop: '2px',
              }}
            />
          </div>
        </div>
        <Card className={styles.appointmentTimeCardContentCardBottom}>
          <div className={styles.appointmentTimeCardContentCardBottomTitle}>
            预约时段
          </div>
          <div className={styles.bottomTime}>
            {timeList?.length ? (
              <Space wrap>
                <CapsuleTabs
                  defaultActiveKey=''
                  onChange={(v) => {
                    const time = v?.split('～')
                    setBottomChangeTime({
                      startTime: time?.[0],
                      endTime: time?.[1],
                      allTime: time?.[0] + '～' + time?.[1],
                    })
                    setScheduleId(time?.[2])
                  }}
                >
                  {timeList?.map((v: any, index: any) => {
                    const title = v?.startTime + '～' + v?.endTime
                    const time =
                      v?.startTime + '～' + v?.endTime + '～' + v?.scheduleId
                    return (
                      <CapsuleTabs.Tab
                        title={title}
                        key={time}
                        disabled={v?.status == 1 ? true : false}
                      />
                    )
                  })}
                </CapsuleTabs>
              </Space>
            ) : (
              <div className={styles.noTimeListName}>暂无预约时段</div>
            )}
          </div>
        </Card>
      </div>
      <div
        className={styles.bottomButton}
        onClick={() => {
          console.log(changeTime, 'changeTime')
          if (changeTime && tab == '0') {
            localStorage.setItem(
              'appointmentTimeDefaultTime',
              changeTime ? changeTime : ''
            )
          } else if (changeTime && tab == '1') {
            localStorage.setItem(
              'defaultTime',
              defaultTime ? defaultTime : changeTime ? changeTime : ''
            )
          }
          if (!bottomChangeTime) {
            Toast.show({
              content: '请先选择预约的时段',
            })
          } else {
            const noSETime = defaultTime
              ? defaultTime
              : changeTime
              ? changeTime
              : nowTime
            const time =
              (defaultTime ? defaultTime : changeTime ? changeTime : nowTime) +
              ' ' +
              bottomChangeTime?.allTime
            history.push({
              pathname: '/appointmentTreatmentPeople',
              state: {
                innerDepartmentId: doctorDetail?.innerDepartmentId, //科室id
                scheduleId: scheduleId,
                realname: doctorDetail?.realname,
                outpatientDepartmentName:
                  doctorDetail?.outpatientDepartmentName,
                appointmentDoctorId: doctorDetail?.id || doctorId, //医生id
                startTime: bottomChangeTime?.startTime,
                endTime: bottomChangeTime?.endTime,
                time: bottomChangeTime?.allTime, //开始结束时间时间段
                allTime: time, //年月日加开始结束时间时间段
                noSETime: noSETime,
                changeTime: changeTime,
              },
            })
          }
        }}
      >
        <div className={styles.bottomButtonText}>下一步</div>
      </div>
      <Popup
        visible={visible}
        position='bottom'
        bodyStyle={{ height: '80vh', borderRadius: '5px 5px 0 0' }}
      >
        <div className={styles.dataTitle}>日期选择</div>
        <img
          className={styles.delectIcon}
          src={deleteCross}
          alt=''
          onClick={() => {
            setVisible(false)
          }}
        ></img>
        <div style={{ padding: '10px' }} className={styles.calenderContent}>
          <Calendar
            ref={calendarRef}
            allowClear={false}
            defaultValue={defaultSingle}
            selectionMode='single'
            min={new Date()}
            max={max}
            renderLabel={(date: any) => {
              let thisDate = moment(date).format('YYYY-MM-DD')
              let status = timeDataList?.filter(
                (item: any) => item && item?.date && item?.date == thisDate
              )?.[0]?.status
              if ((dataValues || moment().format('YYYY-MM-DD')) == thisDate) {
                return (
                  <span className={styles.selectColor}>
                    {getAppointmentTimeName(status) ||
                      getAppointmentTimeName(2)}
                  </span>
                )
              } else {
                return (
                  <span
                    className={
                      status == 0
                        ? styles.statusZero
                        : status == 1
                        ? styles.statusOne
                        : styles.statusTwo
                    }
                  >
                    {getAppointmentTimeName(status) ||
                      getAppointmentTimeName(2)}
                  </span>
                )
              }
            }}
            onChange={(value) => {
              setDataValues(moment(value).format(DateTimeFormats))
              console.log(moment(value).format(DateTimeFormats), 'value')
            }}
          />
          <div className={styles.bottomButtons}>
            <div
              className={styles.bottomButtonTexts}
              onClick={() => {
                setVisible(false)
                setChangeTime(dataValues)
                localStorage.setItem('defaultTime', dataValues)
              }}
            >
              确认
            </div>
          </div>
        </div>
      </Popup>
    </div>
  )
}
